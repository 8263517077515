@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $primary-font;
}

.MuiInputLabel-root {
  .Mui-focused {
    color: rgb(33, 43, 54) !important;
  }
}

// .MuiInputBase-root {
//   .Mui-focused {
//     .MuiOutlinedInput-notchedOutline {
//       border-color: rgb(33, 43, 54) !important;
//       border-width: 1px;
//     }
//   }
// }

.MuiList-root {
  padding: 0 !important;
}

// .MuiPaper-root {
//   background-color: #ffffff !important;
//   box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
//     var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
//   --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
//     0 4px 6px -4px rgba(0, 0, 0, 0.1) !important;
//   --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
//     0 4px 6px -4px var(--tw-shadow-color) !important;
//   animation-duration: 0.3s !important;
//   transition-property: box-shadow !important;
//   transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
//   border-width: 1px !important;
//   border-radius: 0.5rem !important;
// }
