.auth_layout_container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
  @media only screen and (max-width: 764px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
