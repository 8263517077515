.sign_in_container {
  h6 {
    height: 88px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    line-height: 1.57143;
    box-sizing: border-box;
    position: fixed;
    z-index: 1100;
    left: 0;
    display: flex;
    align-items: center;
  }
  .form_container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 400px;
    min-height: 97vh;
    text-align: center;
    justify-content: center;
    padding: 96px 2rem;
    @media only screen and (max-width: 400px) {
      padding: 96px 1rem;
    }
    h5 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
      color: rgb(33, 43, 54);
    }
    p {
      margin: 16px 0px 40px 0px;
      text-align: start;
      line-height: 1.57143;
      font-size: 0.875rem;
      font-weight: 400;
      span {
        font-weight: 600;
        line-height: 1.57143;
        font-size: 0.875rem;
        color: rgb(0, 171, 85);
        cursor: pointer;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      .password_button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
        background-color: transparent;
        cursor: pointer;
        user-select: none;
        vertical-align: middle;
        appearance: none;
        text-align: center;
        font-size: 1.5rem;
        color: rgb(99, 115, 129);
        outline: 0px;
        border-width: 0px;
        border-style: initial;
        border-color: initial;
        border-image: initial;
        margin: 0px -12px 0px 0px;
        text-decoration: none;
        flex: 0 0 auto;
        padding: 8px;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        min-width: 0;
        overflow: visible;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
      }
      .login_button {
        margin-top: 16px;
      }
      .sign_container {
        margin: 24px 0px 0px;
        color: rgb(99, 115, 129);
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        text-align: center;
        a {
          text-decoration: underline rgba(33, 43, 54, 0.4);
          color: rgb(33, 43, 54);
          line-height: 1.5;
          font-size: 0.75rem;
          font-weight: 400;
          cursor: pointer;
        }
      }
    }
  }
}
