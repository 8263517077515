.main_navbar_container {
  width: 100%;
  position: fixed;
  z-index: 1100;
  top: 0px;
  left: auto;
  right: 0px;
  .bottom_shadow {
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    height: 24px;
    z-index: -1;
    opacity: 0.48;
    // border-radius: 50%;
    position: absolute;
    // width: calc(100% - 48px);
    box-shadow: rgba(145, 158, 171, 0.16) 0px 8px 16px 0px;
  }
  .modifiy_navbar {
    height: 64px !important;
    @media only screen and (max-width: 400px) {
      height: 100%;
      min-height: 80px;
    }
  }
  .navbar_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    backdrop-filter: blur(6px);
    background-color: rgba(255, 255, 255, 0.8);
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1);
    max-width: 1200px;
    margin: auto;
    padding-left: 24px;
    padding-right: 24px;
    padding: 1rem 2rem;
    @media only screen and (max-width: 764px) {
      padding: 1rem;
    }
    @media only screen and (max-width: 400px) {
      height: 100%;
      min-height: 100px;
      flex-direction: column;
    }
    .brand_container {
      h3 {
        cursor: pointer;
        font-weight: 800;
        line-height: 1.33333;
        font-size: 1.5rem;
        @media only screen and (max-width: 400px) {
          margin-bottom: 0.3rem;
        }
      }
    }
    .details_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .btn_container {
        margin: 0px 0px 0px 20px;
        button {
          min-width: 40px;
        }
        svg {
          width: 24px;
          height: 24px;
          fill: #fff;
        }
      }
      p {
        margin: 0px 0px 0px 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        font-weight: 600;
        line-height: 1.57143;
        font-size: 0.875rem;
        color: rgb(33, 43, 54);
        outline: 0px;
        cursor: pointer;
        position: relative;
        color: rgb(33, 43, 54);
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
        @media only screen and (max-width: 400px) {
          margin: 0px 0px 0px 20px;

          &:first-child {
            margin: 0;
          }
        }
        &:hover {
          opacity: 0.48;
          background-color: transparent;
        }
      }
      .selected {
        color: rgb(0, 171, 85);
        &::before {
          content: "";
          border-radius: 50%;
          position: absolute;
          width: 6px;
          height: 6px;
          left: -14px;
          opacity: 0.48;
          background-color: currentcolor;
        }
      }
    }
  }
}
