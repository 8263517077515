.main_product_card_container {
  // position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .product_card_container {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(33, 43, 54) !important;
    background-image: none !important;
    position: relative;
    box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
      rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important;
    z-index: 0;
    max-height: 600px;
    filter: none;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
    overflow: hidden;
    border-radius: 16px !important;
    max-width: 237.47px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      max-width: 100%;
      opacity: 1;
      transition: opacity 0.3s;
      border-radius: 12px;
      // &:hover .chart_paper_container {
      //   visibility: visible;
      // }
    }
    hr {
      margin: 0px 0px 8px;
      flex-shrink: 0;
      border-width: 0px 0px thin;
      border-color: rgba(145, 158, 171, 0.2);
      border-style: dashed;
    }
    .title_container {
      h6 {
        margin: 0px;
        font-weight: 600;
        line-height: 1.57143;
        font-size: 0.875rem;
        padding: 8px 8px 4px;
        white-space: nowrap;
      }
      p {
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0px 8px 8px;
        span {
          line-height: 1.5;
          font-size: 0.75rem;
          font-weight: 400;
          color: rgb(145, 158, 171);
          margin: 0px 4px 0px 0px;
        }
      }
    }
    .details_container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: center;
      margin-bottom: 16px;
      gap: 3px;
      .button_container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
      }
      .color_strip {
        bottom: 0px;
        right: 0px;
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 40px solid transparent;
        border-bottom: 40px solid rgb(76, 175, 80);
      }
      .text_1 {
        margin: 0px 0px 12px;
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        color: rgb(145, 158, 171);
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.25rem;
        }
      }
      .text_2 {
        margin: 0px;
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 0.75rem;
          height: 0.75rem;
          margin-right: 0.25rem;
        }
      }
    }
  }
}

.chart_paper_container {
  // position: absolute;
  // z-index: 10;
  // display: none;
  // visibility: hidden;
  // top: 0;
  // left: 0;
  // max-width: 500px;
  min-width: 500px;
  width: 100%;
  @media only screen and (max-width: 900px) {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 90%;
    margin: auto;
  }
  .chart_container {
    padding: 1rem;
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    text {
      font-size: 0.7rem;
    }
    .title_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
      h5 {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: rgb(55, 65, 81);
        font-weight: 600;
      }
      span {
        font-size: 0.75rem;
        line-height: 1rem;
        color: rgb(107, 114, 128);
      }
    }
  }
}
