.home_side_bar_container {
  .selection_container {
    .select_box {
      margin: 16px 0px 0px;
      &:first-child {
        margin: 0;
      }
    }
  }
  .filter_container {
    .range_filter {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 16px;
      span {
        margin: 0px 0px 8px 0px;
        line-height: 1.5;
        font-size: 0.75rem;
        font-weight: 400;
        color: rgb(145, 158, 171);
        text-align: left;
        font-style: italic;
      }
      .date_container {
        position: relative;
        .start_adornment {
          cursor: pointer;
        }

        .static_date {
          position: absolute;
          bottom: 0;
          z-index: 100;

          background-color: rgb(255, 255, 255);
          box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
            rgba(145, 158, 171, 0.12) 0px 12px 24px -4px !important;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) !important;
        }
      }
      .input_container {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
